import enUS from '../en-us';

const enCA = {
  common: {
    ...enUS.common,
    navigation: {
      ...enUS.common.navigation,
      whereToBuy: 'Where To Buy',
    },
    storeLocator: {
      ...enUS.common.storeLocator,
      inputLocation: {
        ...enUS.common.storeLocator.inputLocation,
        label: 'Enter location or postal code',
      },
    },
    redirectModal: {
      headline: 'Are you visiting from ${1}?',
      subheadline: 'Visit your regional site for more relevant information',
      redirectCta: 'Take me there',
      stayCta: 'Stay here',
      closeModal: 'Close dialog window',
    },
  },
  product: {
    ...enUS.product,
    hero: {
      ...enUS.product.hero,
      whereToBuy: 'Where to Buy',
    },
  },
};

export default Object.assign({}, enUS, enCA);
