/* eslint-disable require-jsdoc */
import '../styles/index.scss';
import {
  HotjarScript,
  FacebookScript,
  GoogleAnalyticsScript,
  TwitterScript,
  PricespiderTrackingScript,
} from '../lib/socialTags';
import { useEffect, useState } from 'react';
import SiteContext from '../components/AppContext';
import { LanguageProvider } from '../lib/LanguageProvider';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { fromEvent, interval } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map, share, switchMap, tap } from 'rxjs/operators';
import * as gaUtils from '../lib/gaUtils';
import { getUserCountry, getLocalesFromCountryCode } from '../lib/utils';
import { locales } from '../siteConfig';
import cookieCutter from 'cookie-cutter';

/**
 * Next js default _app file
 *
 * @private
 */
function PetcureanSites({ Component, pageProps }) {
  const router = useRouter();
  const [userLocale, setUserLocale] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const context = {
    brand: pageProps.brand,
    locale: pageProps.locale.toLocaleLowerCase(),
    userLocale,
    localeMap: pageProps.localeMap,
    countryCode,
    instagramClientToken: pageProps.instagramClientToken,
    // this observable is used to determine when the viewport changes from medium to large.
    // It's going to be accessible through the context so any component can
    // subscribe to it, as it is globally shared, the share operator is needed
    // in order to emit to every subscribe.
    largeBreakpointChange$: process.browser
      ? fromEvent(window, 'resize').pipe(
          share(),
          debounce(() => interval(250)),
          map(() => window.innerWidth > 1023),
          distinctUntilChanged(),
        )
      : null,
    escPressed$: process.browser
      ? fromEvent(document, 'keydown').pipe(
          share(),
          filter((e) => e.code === 'Escape'),
        )
      : null,
  };

  const [tabbing, setTabbing] = useState(false);
  useEffect(() => {
    const mouseMove$ = fromEvent(document, 'mousemove');
    const tabPressed$ = fromEvent(document, 'keydown').pipe(filter((e) => e.code === 'Tab'));

    // this is a High order observable, what is going here is:
    //  - the first step is waiting for a Tab key is pressed
    //  - as soon as the user press tabs, the observable changes the state as a side effect (tap)
    //  - we stop listening for tabs and using switchMap we now wait for a mousemove event
    //  - as soon as there's a mouse move, this observable emits and then we wait for
    //  the next tab pressed
    const tabbing$ = tabPressed$.pipe(
      tap(() => {
        setTabbing(true);
      }),
      switchMap(() => mouseMove$.pipe(map(() => false))),
    );

    const tabbingSubscription = tabbing$.subscribe((tabbing) => {
      setTabbing(tabbing);
    });

    const setLocale = (countryCode) => {
      setCountryCode(countryCode.toLowerCase());
      const localesFromCountry = getLocalesFromCountryCode(countryCode.toLocaleLowerCase());
      if (localesFromCountry.length > 0) {
        if (localesFromCountry.length === 1) {
          setUserLocale(localesFromCountry[0]);
          return;
        }
        const browserLang = navigator.language.substr(0, 2);
        const locale = localesFromCountry.find((locale) => locale.startsWith(browserLang));
        let userLocale = locales.find(
          (appLocale) => locale?.toLowerCase() === appLocale.toLowerCase(),
        );
        if (typeof userLocale === 'undefined') {
          userLocale = locales.find((appLocale) => locale?.substr(0, 2) === appLocale.substr(0, 2));
        }
        if (typeof userLocale === 'undefined') {
          userLocale = pageProps.locale;
        }
        setUserLocale(userLocale);
      } else {
        setUserLocale('en');
      }
    };
    if (!process.env.NEXT_PUBLIC_IGNORE_COUNTRY) {
      const countryCode = cookieCutter.get('countryCode');
      if (countryCode) {
        setLocale(countryCode);
      } else {
        getUserCountry().then((countryCode) => {
          const expires = new Date();
          expires.setDate(expires.getDate() + 1);
          cookieCutter.set('countryCode', countryCode, { expires });
          setLocale(countryCode);
        });
      }
    }

    return () => {
      tabbingSubscription.unsubscribe();
    };
  }, [pageProps.locale]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gaUtils.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeComplete', () => {
      window.scroll({
        top: 0,
        left: 0,
      });
    });

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // Google Analytics tracking ID
  const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
  // Facebook pixel ID
  const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
  // Hotjar tracking ID
  const HJ_TRACKING_ID = process.env.NEXT_PUBLIC_HJ_TRACKING_ID;
  // Twitter tracking ID
  const TW_TRACKING_ID = process.env.NEXT_PUBLIC_TW_TRACKING_ID;
  // Pricespider Tracking ID
  const PS_TRACKING_ID = process.env.NEXT_PUBLIC_PRICESPIDER_ACCOUNT_NUMBER;

  return (
    <>
      {GA_TRACKING_ID && <GoogleAnalyticsScript trackingId={GA_TRACKING_ID} />}
      {HJ_TRACKING_ID && <HotjarScript trackingId={HJ_TRACKING_ID} />}
      {FB_PIXEL_ID && <FacebookScript pixelId={FB_PIXEL_ID} />}
      {TW_TRACKING_ID && <TwitterScript trackingId={TW_TRACKING_ID} />}
      {PS_TRACKING_ID && (
        <PricespiderTrackingScript
          accountNumber={PS_TRACKING_ID}
          brand={pageProps.brand}
          locale={pageProps.locale.toLocaleLowerCase()}
        />
      )}

      <LanguageProvider localization={pageProps.localization}>
        <SiteContext.Provider value={context}>
          <Component {...pageProps} className={cx({ tabbing })} />
        </SiteContext.Provider>
      </LanguageProvider>
    </>
  );
}

export default PetcureanSites;
