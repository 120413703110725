/* eslint-disable max-len */
import enUS from '../en-us/index';

const heIL = {
  common: {
    ctas: {
      close: 'סגור',
      learnMore: 'לקבלת מידע נוסף',
      learnMoreAbout: 'לקבלת מידע נוסף אודות',
      viewDetails: 'למידע נוסף >',
      viewDetailsAbout: 'למידע נוסף >',
      viewRecipe: 'למתכונים >>',
      contactUs: 'צרו קשר איתנו',
    },
    navigation: {
      regionSelectorLabel: 'בורר אזור',
      primaryNavLabel: 'נווט ראשי',
      whereToBuy: 'היכן לקנות?',
      selectYourRegion: 'בחרו את אזורכם',
      skipNavigation: 'דלגו לתוכן העיקרי',
    },
    faq: {
      faqs: 'שאלות נפוצות',
    },
    forms: {
      general: {
        /* eslint-disable max-len */
        termsLegend: 'ודאו שאתם מסכימים',
        fieldErrorMsg: 'שדה זה ריק או לא חוקי, אנא מלאו אותו כראוי לפני שליחתו',
        fileInvalidType: 'נשלח קובץ בתבנית שאיננה חוקית, אנא נסו שוב',
        fileTooLarge: 'נשלח קובץ גדול מדי, אנא העלו גרסה קטנה יותר ממנו',
        fileTooSmall: 'שדה זה ריק או לא חוקי, אנא מלאו אותו כראוי לפני שליחתו',
        tooManyFiles: 'הזנתם יותר מדי קבצים, אנא נסו שוב',
        submitBtnText: 'שליחה',
        submitErrorMsg: 'אנא ודאו שכל השדות חובה מולאו כהלכה ונסו שוב',
        networkErrorMsg: 'הייתה שגיאה בבקשה לשרת. אנא נסו שוב או צרו איתנו קשר בטלפון או בדוא"ל',
        successMsg: 'המידע שלכם נשלח בהצלחה',
        selectFile: 'בחרו קובץ',
        dragAndDrop: 'גררו ושחררו את הקבצים שלכם כאן או לחצו כדי לבחור',
        files: 'קבצים',
        fileLimit: 'אנא הוסף רק ${1} קבצים ככל היותר',
        textTooLong: 'שדה זה מוגבל ל-${1} תווים',
        textTooShort: 'בשדה זה נדרשים לפחות ${1} תווים',
        /* eslint-enable max-len */
      },
    },
    rate: {
      starLabel: 'כוכב',
      starsLabel: 'כוכבים',
    },
    success: {
      message: 'תודה על המשוב שלכם!',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: 'קמעונאים מקומיים',
        buyOnlineText: 'קניה מקוונת',
      },
      inputLocation: {
        label: 'הזינו מיקום או מיקוד',
        submit: 'שליחת חיפוש',
      },
      mapControls: {
        curbsideLabel: 'קבלת קנייה במכוניתכם זמינה',
        shopOnlineLabel: 'קניה מקוונת זמינה',
        zoomInLabel: 'הגדילו במפה',
        zoomOutLabel: 'הקטינו במפה',
      },
      filters: {
        filters: 'מסננים',
        filterApplied: 'המסנן הוחל',
        filtersApplied: 'המסננים הוחלו',
        clear: 'ניקוי',
        clearFilters: 'ניקוי מסננים',
        openFilters: 'פתיחת מסננים',
        closeFilters: 'סגירת מסננים',
        appliedFilters: 'הוחלו {1}$ מסננים, לחצו על המקש ENTER לסגירתם',
        noFiltersApplied: '0 מסננים הוחלו',
        noFilters: 'לא נבחרו מסננים',
        numFiltersApplied: 'יש להחיל {1}$ מסננים',
        numFilterApplied: 'החל מסנן 1',
        filterStores: 'סננו חנויות',
      },
      map: {
        title: '$מיקומים שבהם זמינים מתכוני {1}',
      },
      infoList: {
        closeIcon: 'סגירת כרטיס',
        curbsidePickup: 'סגירת כרטיס',
        directions: 'הנחיות להגעה',
        website: 'אתר אינטרנט',
        buyOnline: 'קניה מקוונת',
        facebook: 'פייסבוק',
        mi: 'מיילים מכם',
        km: 'ק“מ מכם',
        loadMore: 'טענו עוד',
        loadMoreStores: 'טעינת חנויות נוספות',
      },
    },
    redirectModal: {
      headline: 'האם אתם באים מ ${1}?',
      subheadline: 'בקרו באתר האזורי שלכם לקבלת מידע רלוונטי נוסף',
      redirectCta: 'קח אותנו לשם',
      stayCta: 'הישארו כאן',
      closeModal: 'הישארו כאן',
    },
    confirmationModal: {
      headline: 'האם אתם בטוחים שברצונכם להתנתק?',
      yes: 'כן',
      no: 'לא',
    },
  },
  product: {
    hero: {
      review: 'סריקה 1',
      reviews: '${1} סקירות',
      askAQuestion: 'שאלו שאלה',
      whereToBuy: 'היכן לקנות?',
    },
    tabs: {
      whatsInside: "מרכיבי המנה",
      nutritionalInformation: 'מידע תזונתי',
      header: {
        nowFresh: "איזון תזונתי מושלם לחיית המחמד",
        goSolutions: "רכיבי פרימיום משותפים נאמנים",
      }
    },
    table: {
      composition: 'סימון תזונתי',
      additives: 'תוספים לק"ג',
      calorieContent: 'קלוריות',
      recommendedFor: 'מומלץ עבור',
      ingredients: 'רכיבים',
      analyticalConstituents: 'מרכיבים אנליטיים',
      amount: 'כמות',
      guaranteedAnalysis: 'ניתוח מובטח',
      viewMore: 'הצגת הכול',
      viewLess: 'הצגת מוגבלת',
      downloadNutrientProfile: 'הורדת פרופיל תזונתי',
    },
    review: {
      sectionTitle: 'המלצות שאתם חייבים להכיר',
      writeAReview: 'הוסיפו המלצה',
      productStandouts: 'מוצרים בולטים',
      noReviewTitle: 'אנחנו רוצים לשמוע מה דעת חיית המחמד שלכם!',
      noReviewSubtitle: 'כרגע אין סקירות עבור המוצר הזה.',
      readMore: 'לקבלת מידע נוסף',
      readMoreFrom: '$קראו עוד מאת {1}',
      lessInformation: 'פחות מידע',
      portraitRespondent: 'דיוקן של המשיב',
      previousPage: 'דיוקן של המשיב',
      nextPage: 'העמוד הבא',
      pageNumber: '${1} עמוד',
      sortReviews: 'מיין סקירות',
      sortLabel: 'סדר לפי:',
      loadingMore: 'טוען...',
      noMore: 'אין עוד סקירות',
      loadMoreCta: 'טענו עוד',
      aggregateSummary: 'מבוסס על ${1} סקירות',
      pagination: 'מציג ${1} מתוך ${2} סקירות',
      feedingSince: 'הזנה עבור',
      petInfoTitle: 'מידע על חיית המחמד',
      petName: 'שם:',
      petAge: 'גיל:',
      petBreed: 'גֶזַע:',
      helpfulPrompt: 'האם זה סייע לך?',
      helpfulPositive: 'כן',
      helpfulNegative: 'לא',
      authorRecommends: 'אני ממליץ את המוצר הזה',
      viewLargeImage: 'ראה תמונה גדולה יותר',
      responseIntro: 'תגובה מ-${1}',
      showOriginal: 'הצג את המקורי',
      translatedBy: 'תורגם על ידי גוגל',
      translateWith: 'תרגם באמצעות גוגל',
      standouts: {
        pickyEaterApproved: 'אכלן בררן מורשה',
        improvedHealth: 'בריאות משופרת',
        easyDigestion: 'עיכול קל',
        skinCoatResults: 'תוצאות על עור/פרווה',
        happyPetParent: 'בעלי חיית מחמד מאושר',
        increasedEnergy: 'אנרגיה מוגברת',
      },
      filters: {
        filterReviews: 'סינון סקירות',
        filterApplied: 'הוחל פילטר ${1}',
        filtersApplied: 'הוחלו ${1} פילטרים',
        result: 'תוצאה ${1}',
        results: '${1} תוצאות',
        filters: 'מסננים',
        filterRecipes: 'סינון מתכונים',
        noFilters: 'לא נבחרו מסננים',
        numFiltersApplied: 'יש להחיל ${1} מסננים',
        numFilterApplied: 'החל מסנן 1',
        clear: 'ניקוי',
        openFilters: 'פתיחת מסננים',
        closeFilters: 'סגירת מסננים',
        clearFilters: 'ניקוי מסננים',
        appliedFilters: 'הוחלו ${1} מסננים, לחצו על המקש אנטר לסגירתם',
        noFiltersApplied: '0 מסננים הוחלו',
        numStars: '${1} כוכבים',
        numStar: 'כוכב ${1}',
        numReviews: '${1} סקירות',
        numReview: 'סריקה 1',
        rating: 'דירוג',
      },
      sort: {
        oldest: 'ישנות ביותר',
        newest: 'החדשות ביותר',
        topRated: 'מדורגים בדירוג הגבוה ביותר',
        lowRated: 'מדורגים בדירוג הנמוך ביותר',
        helpfulness: 'המועילות ביותר',
      },
    },
    feedingGuide: {
      eyebrow: 'מדריך האכלה',
      dogTitle: 'התאימו את המנה המושלמת לכלב שלכם',
      catTitle: 'התאימו את המנה המושלמת לחתול שלכם',
      moreInfo: 'מידע מפורט',
      bodyChartCTALabel: 'טבלת ציון גוף',
      petNeeds: 'חיית המחמד שלכם זקוקה ${1} ליום.',
      contactText: 'צרו קשר איתנו ונעזור לכם למצוא את המתכון המושלם.',
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: '${1} המסנן הוחל',
      filtersApplied: '${1} המסננים הוחלו',
      result: '${1} תוצאה',
      results: '${1} תוצאות',
      filters: 'מסננים',
      filterRecipes: 'סינון מתכונים',
      noFilters: 'לא נבחרו מסננים',
      numFiltersApplied: 'יש להחיל ${1} מסננים',
      numFilterApplied: 'החל מסנן 1',
      clear: 'ניקוי',
      openFilters: 'פתיחת מסננים',
      closeFilters: 'סגירת מסננים',
      clearFilters: 'ניקוי מסננים',
      appliedFilters: 'הוחלו {1}$ מסננים, לחצו על המקש אנטר לסגירתם',
      noFiltersApplied: '0 מסננים הוחלו',
      filterCategories: {
        petType: 'סוג חיית המחמד',
      }
    },
  },
  categoryLandingSyntheticTags: {
    dog: 'כלב',
    cat: 'חתול'
  },
  blogPost: {
    authors: {
      authors: 'מחברים',
      author: 'מחבר',
    },
  },
  blogLanding: {
    featured: 'מוצג',
    pagination: 'מציג {1}$ - {2}$ של {3}$ פריטים',
  },
  countries: {
    af: "אפגניסטן",
    ax: "איי אלנד",
    al: "אלבניה",
    dz: "אלג'יריה",
    as: "סמואה האמריקנית",
    ad: "אנדורה",
    ao: "אנגולה",
    ai: "אנגווילה",
    aq: "אנטארקטיקה",
    ag: "אנטיגואה וברבודה",
    ar: "ארגנטינה",
    am: "אַרְמֶנִיָה",
    aw: "ארובה",
    au: "אוֹסטְרַלִיָה",
    at: "אוֹסְטְרֵיָה",
    az: "אזרביג'אן",
    bs: "בהאמה",
    bh: "בחריין",
    bd: "בנגלדש",
    bb: "ברבדוס",
    by: "בלארוס",
    be: "בלגיה",
    bz: "לליז",
    bj: "בנין",
    bm: "ברמודה",
    bt: "Bhutan",
    bo: "בוליביה",
    ba: "בוסניה והרצגובינה",
    bw: "בוצואנה",
    bv: "איילנד איילנד",
    br: "בְּרָזִיל",
    io: "הטריטוריה הבריטית האוקיינוס ​​ההודי",
    bn: "ברוניי דארוסלאם",
    bg: "בולגריה",
    bf: "בורקינה פאסו",
    bi: "בורונדי",
    kh: "קמבודיה",
    cm: "קמרון",
    ca: "קנדה",
    cv: "קייפ ורדה",
    ky: "איי קיימן",
    cf: "הרפובליקה המרכז - אפריקאית",
    td: "Chad",
    cl: "צ 'ילה",
    cn: "חרסינה",
    cx: "אי חג המולד",
    cc: "איי קוקוס (קילינג)",
    co: "קולומביה",
    km: "קומורו",
    cg: "קונגו",
    cd: "הרפובליקה הדמוקרטית של הקונגו",
    ck: "איי קוק",
    cr: "קוסטה ריקה",
    ci: "קוט ד'אייר",
    hr: "קרואטיה",
    cu: "קובה",
    cy: "קַפרִיסִין",
    cz: "הרפובליקה הצ'כית",
    dk: "דנמרק",
    dj: "ג 'יבוטי",
    dm: "דומיניקה",
    do: "הרפובליקה הדומיניקנית",
    ec: "אקוודור",
    eg: "מִצְרַיִם",
    sv: "אל סלבדור",
    gq: "גיניאה המשוונית",
    er: "אריתריאה",
    ee: "אסטוניה",
    et: "אֶתִיוֹפִּיָה",
    fk: "איי Falkland (Malvinas)",
    fo: "איי פרו",
    fj: "פיג'י",
    fi: "פינלנד",
    fr: "צָרְפַת",
    gf: "גואיאנה צרפתית",
    pf: "פולינזיה הצרפתית",
    tf: "צרפתים דרום דרום",
    ga: "גאבון",
    gm: "גמביה",
    ge: "גאורגיה",
    de: "גֶרמָנִיָה",
    gh: "גאנה",
    gi: "גיברלטר",
    gr: "יָוָן",
    gl: "גרינלנד",
    gd: "גרנדה",
    gp: "גוואדלופ",
    gu: "גואם",
    gt: "גואטמלה",
    gg: "גרנזי",
    gn: "גינאה",
    gw: "גינאה ביסאו",
    gy: "גיאנה",
    ht: "האיטי",
    hm: "שמע האי ומקדונלדס איי",
    va: "הקדוש (מדינת הוותיקן)",
    hn: "הונדורס",
    hk: "הונג קונג",
    hu: "הונגריה",
    is: "אִיסלַנד",
    in: "הוֹדוּ",
    id: "אִינדוֹנֵזִיָה",
    ir: "הרפובליקה האסלאמית של איראן",
    iq: "עִירַאק",
    ie: "אירלנד",
    im: "האי מאן",
    il: "ישראל",
    it: "אִיטַלִיָה",
    jm: "ג'מייקה",
    jp: "יפן",
    je: "ג 'רזי",
    jo: "יַרדֵן",
    kz: "קזחסטן",
    ke: "קניה",
    ki: "קיריבטי",
    kp: "הרפובליקה הדמוקרטית של קוריאה",
    kr: "הרפובליקה של קוריאה",
    kw: "כווית",
    kg: "קירגיזסטן",
    la: "הרפובליקה הדמוקרטית של לאו אנשים",
    lv: "לטביה",
    lb: "ללבנון",
    ls: "לנסוטו",
    lr: "ליבריה",
    ly: "הערבי הערבי Jamahiriya",
    li: "ליכטנשטיין",
    lt: "ליטא",
    lu: "לוקסמבורג",
    mo: "מַקאו",
    mk: "מוּקדוֹן",
    mg: "מדגסקר",
    mw: "מאלאווי",
    my: "מלזיה",
    mv: "האיים המלדיביים",
    ml: "מאלי",
    mt: "מלה",
    mh: "איי מרשל",
    mq: "מרטיניק",
    mr: "מאוריטניה",
    mu: "מאוריציוס",
    yt: "Mayotte",
    mx: "מקסיקו",
    fm: "מיקרונזיה",
    md: "מולדובה",
    mc: "מוֹנוֹקוֹ",
    mn: "מונגוליה",
    ms: "מונטסראט",
    ma: "מָרוֹקוֹ",
    mz: "מוזמביק",
    mm: "מיאנמר",
    na: "נמיביה",
    nr: "Nauru",
    np: "נפאל",
    nl: "הולנד",
    an: "האנטילים ההולנדיים",
    nc: "קלדוניה החדשה",
    nz: "ניו זילנד",
    ni: "ניקרגואה",
    ne: "ניג'ר",
    ng: "ניגריה",
    nu: "נַיָא",
    nf: "נורפולק איילנד",
    mp: "איים צפון מריאנה",
    no: "נורווגיה",
    om: "אוּמָם",
    pk: "פקיסטן",
    pw: "פאלאו",
    ps: "שטח פלסטיני, כבוש",
    pa: "פנמה",
    pg: "פפואה גינאה החדשה",
    py: "פרגוואי",
    pe: "פרו",
    ph: "פיליפינים",
    pn: "פיטקיר",
    pl: "פּוֹלִין",
    pt: "פּוֹרטוּגָל",
    pr: "פוארטו ריקו",
    qa: "קטר",
    re: "איחוד",
    ro: "רומניה",
    ru: "הפדרציה הרוסית",
    rw: "רואנדה",
    sh: "סנט הלנה",
    kn: "קיטס סנט ונוויס",
    lc: "סנט לוסיה",
    pm: "סנט פייר ומיקלון",
    vc: "וינסנט הקדוש ו ה - גרנידיים",
    ws: "סמואה",
    sm: "סן מרינו",
    st: "סאו טומה ופרינסיפה",
    sa: "ערב הסעודית",
    sn: "סנגל",
    cs: "סרביה ומונטנגרו",
    sc: "סיישל",
    sl: "סיירה לאונה",
    sg: "סינגפור",
    sk: "סלובקיה",
    si: "סלובניה",
    sb: "איי שלמה",
    so: "סומליה",
    za: "דרום אפריקה",
    gs: "דרום ג'ורג'יה ואיי סנדוויץ 'דרום",
    es: "סְפָרַד",
    lk: "סרי לנקה",
    sd: "סודאן",
    sr: "סורינאם",
    sj: "סוולברד ויאן מאי",
    sz: "סווזילנד",
    se: "שבדיה",
    ch: "שוויץ",
    sy: "הרפובליקה הערבית של סוריה",
    tw: "טייוואן",
    tj: "טג'יקיסטן",
    tz: "טנזניה, הרפובליקה המאוחדת של",
    th: "תאילנדית",
    tl: "טימור-אלסטה",
    tg: "ללכת",
    tk: "טוקלאו",
    to: "טונגה",
    tt: "טרינידד וטובגו",
    tn: "תוניסיה",
    tr: "טורקיה",
    tm: "טורקמניסטן",
    tc: "איי טורקס וקאיקוס",
    tv: "Tuvalu",
    ug: "אוגנדה",
    ua: "אוקראינה",
    ae: "איחוד האמירויות הערביות",
    gb: "הממלכה המאוחדת",
    us: "ארצות הברית",
    um: "ארצות הברית איי מרוחקים",
    uy: "אורוגוואי",
    uz: "אוזבקיסטן",
    vu: "ונואטו",
    ve: "ונצואלה",
    vn: "וייט נאם",
    vg: "איי הבתולה, הבריטים",
    vi: "איי הבתולה, ארה'ב",
    wf: "וואליס ופוטונה",
    eh: "סהרה המערבית",
    ye: "תֵימָן",
    zm: "זאמביה",
    zw: "זימבבואה",
    undef: 'בינלאומי'
  },
};

export default Object.assign({}, enUS, heIL);