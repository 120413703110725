import enUS from '../en-us';
const en = {
  product: {
    ...enUS.product,
    hero: {
      ...enUS.product.hero,
      whereToBuy: 'Where To Buy',
    },
  },
};

export default Object.assign({}, enUS, en);
