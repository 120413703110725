export default {
  common: {
    ctas: {
      close: 'Close',
      learnMore: 'Learn more',
      learnMoreAbout: 'Learn more about',
      viewDetails: 'View details',
      viewDetailsAbout: 'View details about',
      viewRecipe: 'View recipe',
      contactUs: 'Contact us',
    },
    navigation: {
      regionSelectorLabel: 'Region selector',
      primaryNavLabel: 'Primary nav',
      whereToBuy: 'Where To Buy',
      selectYourRegion: 'Select your region',
      skipNavigation: 'Skip to Main Content',
    },
    faq: {
      faqs: 'Frequently asked questions',
    },
    forms: {
      general: {
        /* eslint-disable max-len */
        termsLegend: 'Check if you agree',
        fieldErrorMsg: 'This field is empty or invalid, please fill it properly before submission',
        fileInvalidType: 'A file submitted is an invalid type, please try again',
        fileTooLarge: 'A file submitted is too large, please upload a smaller version',
        fileTooSmall: 'A file submitted is empty, please try again',
        tooManyFiles: 'You have entered too many files, please try again',
        fileLimit: 'Please only enter a maximum of ${1} files',
        submitBtnText: 'Submit',
        submitErrorMsg: 'Please ensure that all required fields are properly filled and try again',
        networkErrorMsg:
          'There was an error in the request to the server. Please try again or contact us via phone or email',
        successMsg: 'Your information was successfully sent',
        selectFile: 'Select file',
        dragAndDrop: 'Drag and drop your files here, or click to select',
        files: 'Files',
        textTooLong: 'This field has a maximum limit of ${1} characters',
        textTooShort: 'This field requires a minimum of ${1} characters',
        /* eslint-enable max-len */
      },
    },
    rate: {
      starLabel: 'star',
      starsLabel: 'stars',
    },
    success: {
      message: 'Thanks for your feedback!',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: 'Local Retailers',
        buyOnlineText: 'Buy Online',
      },
      inputLocation: {
        label: 'Enter location or ZIP code',
        submit: 'Submit Search',
      },
      mapControls: {
        curbsideLabel: 'Curbside pickup available',
        shopOnlineLabel: 'Online shopping available',
        zoomInLabel: 'Zoom in on the map',
        zoomOutLabel: 'Zoom out on the map',
      },
      filters: {
        filters: 'Filters',
        filterApplied: 'Filter applied',
        filtersApplied: 'Filters applied',
        clear: 'Clear',
        clearFilters: 'Clear Filters',
        openFilters: 'Open Filters',
        closeFilters: 'Close Filters',
        appliedFilters: 'There are ${1} filters applied, press enter to close the filters',
        noFiltersApplied: '0 filters applied',
        noFilters: 'No Filters Selected',
        numFiltersApplied: 'Apply ${1} Filters',
        numFilterApplied: 'Apply 1 Filter',
        filterStores: 'Filter Stores',
        filterReviews: 'Filter Reviews',
      },
      map: {
        title: 'Locations where ${1} recipes are available',
      },
      infoList: {
        closeIcon: 'Close Card',
        curbsidePickup: 'Curbside pickup available',
        directions: 'Directions',
        website: 'Website',
        buyOnline: 'Buy Online',
        facebook: 'Facebook',
        mi: 'mi away from you',
        km: 'km away from you',
        loadMore: 'Load more',
        loadMoreStores: 'Load more stores',
      },
    },
    redirectModal: {
      headline: 'Are you visiting from ${1}?',
      subheadline: 'Visit your regional site for more relevant information',
      redirectCta: 'Take me there',
      stayCta: 'Stay here',
      closeModal: 'Close dialog window',
    },
    confirmationModal: {
      headline: 'Are you sure you want to leave?',
      yes: 'Yes',
      no: 'No',
    },
  },
  product: {
    hero: {
      review: '1 review',
      reviews: '${1} reviews',
      askAQuestion: 'Ask a Question',
      buyOnline: 'Buy Online',
      buyOnlineOther: 'All Retailers',
      whereToBuy: 'Find a Store',
    },
    tabs: {
      whatsInside: "What's inside",
      nutritionalInformation: 'Nutritional information',
      header: {
        gather: "Certified and natural ingredients from our trusted partners",
        goSolutions: "Premium ingredients from trusted partners",
        nowFresh: "Perfectly balanced nutrition for your pet",
        summit: "Accessible nutrition your pet will crave"
      },
    },
    table: {
      composition: 'Composition',
      additives: 'Additives Per KG',
      calorieContent: 'Calorie Content',
      recommendedFor: 'Recommended For',
      ingredients: 'Ingredients',
      analyticalConstituents: 'Analytical Constituents',
      amount: 'Amount',
      guaranteedAnalysis: 'Guaranteed Analysis',
      viewMore: 'View all',
      viewLess: 'View less',
      downloadNutrientProfile: 'Download Nutrient Profile',
    },
    review: {
      sectionTitle: 'What other pet parents are saying',
      writeAReview: 'Write a Review',
      productStandouts: 'Product Standouts',
      noReviewTitle: 'We want to hear what your pet thinks!',
      noReviewSubtitle: 'There are currently no reviews for this product.',
      readMore: 'Read more',
      readMoreFrom: 'Read More From ${1}',
      lessInformation: 'Less information',
      portraitRespondent: 'Portrait of Respondent',
      previousPage: 'Previous Page',
      nextPage: 'Next Page',
      pageNumber: 'Page ${1}',
      sortReviews: 'Sort Reviews',
      sortLabel: 'Sort by:',
      loadingMore: 'Loading...',
      noMore: 'No more reviews',
      loadMoreCta: 'Load More',
      aggregateSummary: 'Based on ${1} reviews',
      pagination: 'Showing ${1} of ${2} Reviews',
      feedingSince: 'Feeding for',
      petInfoTitle: 'Pet Information',
      petName: 'Name:',
      petAge: 'Age:',
      petBreed: 'Breed:',
      helpfulPrompt: 'Was This Helpful?',
      helpfulPositive: 'Yes',
      helpfulNegative: 'No',
      authorRecommends: 'I Recommend This Product',
      viewLargeImage: 'View larger image',
      responseIntro: 'Response From ${1}',
      showOriginal: 'Show Original',
      translatedBy: 'Translated by Google',
      translateWith: 'Translate with Google',
      standouts: {
        pickyEaterApproved: 'Picky Eater Approved',
        improvedHealth: 'Improved health',
        easyDigestion: 'Easy Digestion',
        skinCoatResults: 'Skin/coat results',
        happyPetParent: 'Happy pet parent',
        increasedEnergy: 'Increased Energy',
      },
      filters: {
        filterReviews: 'Filter Reviews',
        filterApplied: '${1} Filter applied',
        filtersApplied: '${1} Filters applied',
        result: '${1} Result',
        results: '${1} Results',
        filters: 'Filters',
        filterRecipes: 'Filter Recipes',
        noFilters: 'No Filters Selected',
        numFiltersApplied: 'Apply ${1} Filters',
        numFilterApplied: 'Apply 1 Filter',
        clear: 'Clear',
        openFilters: 'Open Filters',
        closeFilters: 'Close Filters',
        clearFilters: 'Clear Filters',
        appliedFilters: 'There are ${1} filters applied, press enter to close the filters',
        noFiltersApplied: '0 filters applied',
        numStars: '${1} Stars',
        numStar: '${1} Star',
        numReviews: '${1} Reviews',
        numReview: '${1} Review',
        rating: 'Rating',
      },
      sort: {
        oldest: 'Oldest',
        newest: 'Newest',
        topRated: 'Top Rated',
        lowRated: 'Lowest Rated',
        helpfulness: 'Most Helpful',
      },
    },
    feedingGuide: {
      eyebrow: 'Feeding Guide',
      dogTitle: 'Find the right amount for your dog',
      catTitle: 'Find the right amount for your cat',
      moreInfo: 'Detailed information',
      bodyChartCTALabel: 'Body Score Chart',
      transitionGuideCTALabel: 'Transition Guide',
      petNeeds: 'Your pet needs about ${1} per day.',
      contactText: "Get in touch and we'll help you find the perfect recipe.",
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: '${1} Filter applied',
      filtersApplied: '${1} Filters applied',
      result: '${1} Result',
      results: '${1} Results',
      filters: 'Filters',
      filterRecipes: 'Filter Recipes',
      noFilters: 'No Filters Selected',
      numFiltersApplied: 'Apply ${1} Filters',
      numFilterApplied: 'Apply 1 Filter',
      clear: 'Clear',
      openFilters: 'Open Filters',
      closeFilters: 'Close Filters',
      clearFilters: 'Clear Filters',
      appliedFilters: 'There are ${1} filters applied, press enter to close the filters',
      noFiltersApplied: '0 filters applied',
      filterCategories: {
        petType: 'Pet Type',
        kibbleSize: 'Kibble Size',
        breedSize: 'Breed Size',
        lifeStage: 'Life Stage',
        foodType: 'Food Type',
        solution: 'Solution',
        recipe: 'Recipe',
        protein: 'Protein',
        supports: 'Supports',
      },
    },
  },
  categoryLandingSyntheticTags: {
    dog: 'Dog',
    cat: 'Cat'
  },
  blogPost: {
    authors: {
      authors: 'Authors',
      author: 'Author',
    },
  },
  blogLanding: {
    featured: 'Featured',
    pagination: 'Showing ${1} - ${2} of ${3} articles',
  },
  countries: {
    af: 'Afghanistan',
    ax: 'Åland Islands',
    al: 'Albania',
    dz: 'Algeria',
    as: 'American Samoa',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguilla',
    aq: 'Antarctica',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    aw: 'Aruba',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bm: 'Bermuda',
    bt: 'Bhutan',
    bo: 'Bolivia',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    bv: 'Bouvet Island',
    br: 'Brazil',
    io: 'British Indian Ocean Territory',
    bn: 'Brunei Darussalam',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cv: 'Cape Verde',
    ky: 'Cayman Islands',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    cx: 'Christmas Island',
    cc: 'Cocos (Keeling) Islands',
    co: 'Colombia',
    km: 'Comoros',
    cg: 'Congo',
    cd: 'The Democratic Republic of the Congo',
    ck: 'Cook Islands',
    cr: 'Costa Rica',
    ci: 'Cote D\'Ivoire',
    hr: 'Croatia',
    cu: 'Cuba',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominican Republic',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    et: 'Ethiopia',
    fk: 'Falkland Islands (Malvinas)',
    fo: 'Faroe Islands',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    gf: 'French Guiana',
    pf: 'French Polynesia',
    tf: 'French Southern Territories',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Greece',
    gl: 'Greenland',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernsey',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hm: 'Heard Island and Mcdonald Islands',
    va: 'Holy See (Vatican City State)',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Islamic Republic Of Iran',
    iq: 'Iraq',
    ie: 'Ireland',
    im: 'Isle of Man',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    je: 'Jersey',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kp: 'Democratic People\'s Republic of Korea',
    kr: 'Republic of Korea',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: 'Lao People\'s Democratic Republic',
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libyan Arab Jamahiriya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mo: 'Macao',
    mk: 'Macedonia',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    mu: 'Mauritius',
    yt: 'Mayotte',
    mx: 'Mexico',
    fm: 'Micronesia',
    md: 'Moldova',
    mc: 'Monaco',
    mn: 'Mongolia',
    ms: 'Montserrat',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    an: 'Netherlands Antilles',
    nc: 'New Caledonia',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Norfolk Island',
    mp: 'Northern Mariana Islands',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestinian Territory, Occupied',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pn: 'Pitcairn',
    pl: 'Poland',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    re: 'Reunion',
    ro: 'Romania',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    sh: 'Saint Helena',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    pm: 'Saint Pierre and Miquelon',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome and Principe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    cs: 'Serbia and Montenegro',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    gs: 'South Georgia and the South Sandwich Islands',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    sj: 'Svalbard and Jan Mayen',
    sz: 'Swaziland',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syrian Arab Republic',
    tw: 'Taiwan',
    tj: 'Tajikistan',
    tz: 'Tanzania, United Republic of',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tc: 'Turks and Caicos Islands',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom',
    us: 'United States',
    um: 'United States Minor Outlying Islands',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Viet Nam',
    vg: 'Virgin Islands, British',
    vi: 'Virgin Islands, U.S.',
    wf: 'Wallis and Futuna',
    eh: 'Western Sahara',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe',
    undef: 'International'
  },
};
