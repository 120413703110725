/* eslint-disable max-len */
import enUS from '../en-us/index';

const ruRU = {
  common: {
    ctas: {
      close: 'Закрыть',
      learnMore: 'Узнать больше',
      learnMoreAbout: 'Подробная информация',
      viewDetails: 'Подробнее',
      viewDetailsAbout: 'Подробнее',
      viewRecipe: 'Подробнее',
      contactUs: 'Контактная информация',
    },
    navigation: {
      regionSelectorLabel: 'Выбор региона',
      primaryNavLabel: 'Первичная навигация',
      whereToBuy: 'Где купить?',
      selectYourRegion: 'Выберите регион',
      skipNavigation: 'Перейти к основному контенту',
    },
    faq: {
      faqs: 'Часто задаваемые вопросы',
    },
    forms: {
      general: {
        termsLegend: 'Проверьте, согласны ли вы',
        fieldErrorMsg: 'Это поле оставлено пустым или содержит ошибку. Чтобы отправить данные, оно должно быть заполнено правильно',
        fileInvalidType: 'Загружаемый файл имеет недопустимый формат. Пожалуйста, попробуйте еще раз',
        fileTooLarge: 'Загружаемый файл слишком велик. Пожалуйста, загрузите файл меньшего размера',
        fileTooSmall: 'Загружаемый файл не содержит данных. Пожалуйста, попробуйте еще раз',
        tooManyFiles: 'Вы загрузили слишком много файлов. Пожалуйста, попробуйте еще раз',
        submitBtnText: 'Отправить',
        submitErrorMsg: 'Проверьте, все ли обязательные поля заполнены должным образом, и попробуйте еще раз',
        networkErrorMsg:
          'При отправке запроса на сервер произошла ошибка. Пожалуйста, попробуйте еще раз или свяжитесь с нами по телефону или электронной почте',
        successMsg: 'Ваша информация была успешно отправлена',
        selectFile: 'Выберите файл',
        dragAndDrop: 'Перетащите файлы сюда или нажмите для загрузки.',
        files: 'Файлы',
        fileLimit: 'Пожалуйста, введите не более ${1} файлов',
        textTooLong: 'Максимальное количество символов в этом поле – ${1}.',
        textTooShort: 'В этом поле должно быть не менее ${1} символов.',
      },
    },
    rate: {
      starLabel: 'звездочка',
      starsLabel: 'звездочки',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: 'Местные ретейлеры',
        buyOnlineText: 'Купить онлайн',
      },
      inputLocation: {
        label: 'Укажите населенный пункт или почтовый индекс',
        submit: 'Начать поиск',
      },
      mapControls: {
        curbsideLabel: 'Выдача заказа на улице',
        shopOnlineLabel: 'Покупка через интернет-магазин',
        zoomInLabel: 'Приблизить карту',
        zoomOutLabel: 'Отдалить карту',
      },
      filters: {
        filters: 'Фильтры',
        filterApplied: 'Фильтр применен',
        filtersApplied: 'Фильтры применены',
        clear: 'Сбросить',
        clearFilters: 'Сбросить фильтры',
        openFilters: 'Открыть фильтры',
        closeFilters: 'Скрыть фильтры',
        appliedFilters: 'Применено фильтров: ${1}. Нажмите «Ввод», чтобы скрыть фильтры',
        noFiltersApplied: '0 фильтров применено',
        noFilters: 'Фильтры не выбраны',
        numFiltersApplied: 'Применить фильтров: ${1}',
        numFilterApplied: 'Применить 1 фильтр',
        filterStores: 'Отфильтровать магазины',
      },
      map: {
        title: 'Магазины, где корма ${1} имеются в наличии',
      },
      infoList: {
        closeIcon: 'Скрыть карточку',
        curbsidePickup: 'Выдача заказа на улице',
        directions: 'Схема проезда',
        website: 'Веб-сайт',
        buyOnline: 'Купить онлайн',
        facebook: 'Facebook',
        mi: 'милях от вас',
        km: 'км от вас',
        loadMore: 'Показать еще',
        loadMoreStores: 'Показать больше магазинов',
      },
    },
    redirectModal: {
      headline: 'Вы зашли на сайт из ${1}?',
      subheadline: 'Для получения более актуальной информации используйте региональную версию сайта',
      redirectCta: 'Перенаправьте меня туда',
      stayCta: 'Остаться здесь',
      closeModal: 'Остаться здесь',
    },
    confirmationModal: {
      headline: 'Вы уверены, что хотите выйти?',
      yes: 'Да',
      no: 'Нет',
    },
  },
  product: {
    hero: {
      review: '1 отзыв',
      reviews: 'Отзывов: ${1}',
      askAQuestion: 'Задать вопрос',
      whereToBuy: 'Где купить?',
    },
    tabs: {
      whatsInside: "Компоненты корма",
      nutritionalInformation: 'Информация о пищевой ценности',
      header: {
        nowFresh: "Идеально сбалансированное питание для вашего питомца",
        goSolutions: "Ингредиенты премиум-класса от проверенных партнеров",
      }
    },
    table: {
      composition: 'Состав',
      additives: 'Количество добавок на кг',
      calorieContent: 'Калорийность',
      recommendedFor: 'Рекомендовано для',
      ingredients: 'Ингредиенты',
      analyticalConstituents: 'Питательные вещества',
      amount: 'Объем',
      guaranteedAnalysis: 'Пищевая ценность',
      viewMore: 'Показать все',
      viewLess: 'Показать меньше',
      downloadNutrientProfile: 'Скачать профиль питательных веществ',
    },
    review: {
      sectionTitle: 'Что говорят другие хозяева?',
      writeAReview: 'Написать отзыв',
      productStandouts: 'Особенности продукта',
      noReviewTitle: 'Мы хотим знать, что думает ваш питомец!',
      noReviewSubtitle: 'Пока что об этом продукте нет отзывов.',
      readMore: 'Подробнее',
      readMoreFrom: 'Читать другие материалы ${1}',
      lessInformation: 'Меньше информации',
      portraitRespondent: 'Информация о респонденте',
      previousPage: 'Предыдущая страница',
      nextPage: 'Следующая страница',
      pageNumber: 'Страница ${1}',
      sortReviews: 'Сортировать отзывы',
      sortLabel: 'Сортировать по:',
      loadingMore: 'Загрузка...',
      noMore: 'Отзывов больше нет',
      loadMoreCta: 'Показать еще',
      aggregateSummary: 'На основе ${1} отзывов',
      pagination: 'Показаны ${1} из ${2} отзывов',
      feedingSince: 'Корм для',
      petInfoTitle: 'Данные питомца',
      petName: 'Имя:',
      petAge: 'Возраст:',
      petBreed: 'Порода:',
      helpfulPrompt: 'Было ли это полезно?',
      helpfulPositive: 'Да',
      helpfulNegative: 'Нет',
      authorRecommends: 'Я рекомендовал(-а) этот продукт',
      viewLargeImage: 'Посмотреть увеличенное изображение',
      responseIntro: 'Ответ от ${1}',
      showOriginal: 'Показать оригинал',
      translatedBy: 'Переведено с помощью Google',
      translateWith: 'Перевести с помощью Google',
      filters: {
        filterReviews: 'Фильтровать по отзывам',
        filterApplied: 'Применен ${1} фильтр',
        filtersApplied: 'Применено ${1} фильтров',
        result: '${1} результат',
        results: '${1} результатов',
        filters: 'Фильтры',
        filterRecipes: 'Фильтровать по составу',
        noFilters: 'Фильтры не выбраны',
        numFiltersApplied: 'Применить фильтров: ${1}',
        numFilterApplied: 'Применить 1 фильтр',
        clear: 'Сбросить',
        openFilters: 'Открыть фильтры',
        closeFilters: 'Скрыть фильтры',
        clearFilters: 'Сбросить фильтры',
        appliedFilters: 'Применено фильтров:${1}. Нажмите «Ввод», чтобы скрыть фильтры',
        noFiltersApplied: '0 фильтров применено',
        numStars: '${1} звезд',
        numStar: '${1} звезда',
        numReviews: 'Отзывов: ${1}',
        numReview: '1 отзыв',
        rating: 'Рейтинг',
      },
      sort: {
        oldest: 'Самые старые',
        newest: 'Последние',
        topRated: 'Самая высокая оценка',
        lowRated: 'Самая низкая оценка',
        helpfulness: 'Самый полезный',
      },
    },
    feedingGuide: {
      eyebrow: 'Рекомендации по питанию',
      dogTitle: 'Рассчитайте правильное количество корма для вашей собаки',
      catTitle: 'Рассчитайте правильное количество корма для вашей кошки',
      moreInfo: 'Подробная информация',
      bodyChartCTALabel: 'Оценка физической формы животного',
      petNeeds: 'Вашему питомцу требуется примерно ${1} корма в день.',
      contactText: 'Свяжитесь с нами, и мы поможем вам выбрать наиболее подходящий для вашего питомца корм.',
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: 'Фильтры применены: ${1}',
      filtersApplied: 'Фильтры применены: ${1}',
      result: 'Результаты: ${1}',
      results: 'Результаты: ${1}',
      filters: 'Фильтры',
      filterRecipes: 'Отфильтровать корма',
      noFilters: 'Фильтры не выбраны',
      numFiltersApplied: 'Применить фильтров: ${1}',
      numFilterApplied: 'Применить 1 фильтр',
      clear: 'Сбросить',
      openFilters: 'Открыть фильтры',
      closeFilters: 'Скрыть фильтры',
      clearFilters: 'Сбросить фильтры',
      appliedFilters: 'Применено фильтров: ${1}. Нажмите «Ввод», чтобы скрыть фильтры',
      noFiltersApplied: '0 фильтров применено',
      filterCategories: {
        petType: 'Вид домашнего животного',
      }
    },
  },
  categoryLandingSyntheticTags: {
    dog: 'Собака',
    cat: 'Кот'
  },
  blogPost: {
    authors: {
      authors: 'Авторы',
      author: 'Автор',
    },
  },
  blogLanding: {
    featured: 'Рекомендуемые',
    pagination: 'Показать ${1} – ${2} из ${3} статей',
  },
  countries: {
    tw: "Тайвань",
    af: "Афганистана ",
    al: "Албании",
    dz: "Алжира",
    as: "Американского Самоа",
    ad: "Андорры",
    ao: "Анголы",
    ai: "Ангильи",
    aq: "Антарктики",
    ag: "Антигуа и Барбуда",
    ar: "Аргентины",
    am: "Армении",
    aw: "Арубы",
    au: "Австралии",
    at: "Австрии",
    az: "Азербайджана",
    bs: "Багамских Островов",
    bh: "Бахрейна",
    bd: "Бангладеша",
    bb: "Барбадоса",
    by: "Беларуси",
    be: "Бельгии",
    bz: "Белиза",
    bj: "Бенина",
    bm: "Бермудских островов",
    bt: "Бутана",
    bo: "Боливии (многонационального государства)",
    bq: "Бонайре, Саба и Синт-Эстатиус",
    ba: "Боснии и Герцеговины",
    bw: "Ботсваны",
    bv: "Острова Буве",
    br: "Бразилии",
    io: "Британской территории в Индийском океане",
    vg: "Британских Виргинских островов",
    bn: "Бруней-Даруссалама",
    bg: "Болгарии",
    bf: "Буркина-Фасо",
    bi: "Бурунди",
    cv: "Кабо-Верде",
    kh: "Камбоджи",
    cm: "Камеруна",
    ca: "Канады",
    ky: "Кайман островов",
    cf: "Центральноафриканской Республики",
    td: "Чада",
    cl: "Чили",
    cn: "Китая",
    hk: "Китая, Специальный административный район Гонконг",
    mo: "Китая, Специальный административный район Макао",
    cx: "острова Рождества",
    cc: "Кокосовых (Килинг) островов",
    co: "Колумбии",
    km: "Коморских Островов",
    cg: "Конго",
    ck: "Острова Кука",
    cr: "Коста-Рики",
    hr: "Хорватии",
    cu: "Кубы",
    cw: "Курасао",
    cy: "Кипра",
    cz: "Чехии",
    ci: "Кот-д'Ивуара",
    kp: "Корейской Народно-Демократической Республики",
    cd: "Демократической Республики Конго",
    dk: "Дании",
    dj: "Джибути",
    dm: "Доминики",
    do: "Доминиканской Республики",
    ec: "Эквадора",
    eg: "Египта",
    sv: "Сальвадора",
    gq: "Экваториальной Гвинеи",
    er: "Эритреи",
    ee: "Эстонии",
    sz: "Эсватини",
    et: "Эфиопии",
    fk: "Фолклендских (Мальвинских) островов",
    fo: "Фарерских островов",
    fj: "Фиджи",
    fi: "Финляндии",
    fr: "Франции",
    gf: "Французской Гвианы",
    pf: "Французской Полинезии",
    tf: "Южных земель (французской заморской территории)",
    ga: "Габона",
    gm: "Гамбии",
    ge: "Грузии",
    de: "Германии",
    gh: "Ганы",
    gi: "Гибралтара",
    gr: "Греции",
    gl: "Гренландии",
    gd: "Гренады",
    gp: "Гваделупы",
    gu: "Гуама",
    gt: "Гватемалы",
    gg: "Гернси",
    gn: "Гвинеи",
    gw: "Гвинеи-Бисау",
    gy: "Гайаны",
    ht: "Гаити",
    hm: "Острова Херд и острова Макдональд",
    va: "Святого Престола",
    hn: "Гондураса",
    hu: "Венгрии",
    is: "Исландии",
    in: "Индии",
    id: "Индонезии",
    ir: "Ирана (Исламской Республики)",
    iq: "Ирака",
    ie: "Ирландии",
    im: "Острова Мэн",
    il: "Израиля",
    it: "Италии",
    jm: "Ямайки",
    jp: "Японии",
    je: "Джерси  ",
    jo: "Иордании",
    kz: "Казахстана",
    ke: "Кении",
    ki: "Кирибати",
    kw: "Кувейта",
    kg: "Кыргызстана",
    la: "Лаосской Народно-Демократической Республики",
    lv: "Латвии",
    lb: "Ливана",
    ls: "Лесото ",
    lr: "Либерии",
    ly: "Ливии",
    li: "Лихтенштейна",
    lt: "Литвы",
    lu: "Люксембурга",
    mg: "Мадагаскара",
    mw: "Малави",
    my: "Малайзии",
    mv: "Мальдивских Островов",
    ml: "Мали",
    mt: "Мальты",
    mh: "Маршалловых Островов",
    mq: "Мартиники",
    mr: "Мавритании",
    mu: "Маврикии",
    yt: "Острова Майотта",
    mx: "Мексики",
    fm: "Микронезии (Федеративные Штаты)",
    mc: "Монако",
    mn: "Монголии",
    me: "Черногории",
    ms: "Монтсеррат",
    ma: "Марокко",
    mz: "Мозамбик",
    mm: "Мьянмы",
    na: "Намибии",
    nr: "Науру",
    np: "Непала",
    nl: "Нидерландов",
    nc: "Новой Каледонии",
    nz: "Новой Зеландии",
    ni: "Никарагуа",
    ne: "Нигера",
    ng: "Нигерии",
    nu: "Ниуэ",
    nf: "Острова Норфолк",
    mp: "Северных Марианских островов",
    no: "Норвегии",
    om: "Омана",
    pk: "Пакистана",
    pw: "Палау",
    pa: "Панамы",
    pg: "Папуа-Новой Гвинеи",
    py: "Парагвая",
    pe: "Перу",
    ph: "Филиппин",
    pn: "Питкэрна",
    pl: "Польши",
    pt: "Португалии",
    pr: "Пуэрто-Рико",
    qa: "Катара",
    kr: "Республики Корея",
    md: "Республики Молдова",
    ro: "Румынии",
    ru: "Российской Федерации",
    rw: "Руанды",
    re: "Реюньона",
    bl: "Святого Варфоломея",
    sh: "Острова Святой Елены",
    kn: "Сент-Китс и Невис",
    lc: "Сент-Люсии",
    mf: "Сен-Мартена",
    pm: "Сен-Пьер и Микелон",
    vc: "Сент-Винсент и Гренадин",
    ws: "Самоа",
    sm: "Сан-Марино",
    st: "Сан-Томе и Принсипи",
    sa: "Саудовской Аравии",
    sn: "Сенегала",
    rs: "Сербии",
    sc: "Сейшельских Островов",
    sl: "Сьерра-Леоне",
    sg: "Сингапура",
    sx: "Синт-Мартена",
    sk: "Словакии",
    si: "Словении",
    sb: "Соломоновых Островов",
    so: "Сомали",
    za: "Южной Африки",
    gs: "Южной Джорджия и Южных Сандвичевых островов",
    ss: "Южного Судана",
    es: "Испании",
    lk: "Шри-Ланки",
    ps: "Палестины",
    sd: "Судана",
    sr: "Суринама",
    sj: "Острова Свальбард и Ян-Майен",
    se: "Швеции",
    ch: "Швейцарии",
    sy: "Сирийской Арабской Республики",
    tj: "Таджикистана",
    th: "Таиланда",
    mk: "Македонии",
    tl: "Тимор-Лешти",
    tg: "Того",
    tk: "Токелау",
    to: "Тонга",
    tt: "Тринидад и тобаго",
    tn: "Туниса",
    tr: "Турции",
    tm: "Туркменистана",
    tc: "Островов Тёркс и Кайкос",
    tv: "Тувалу",
    ug: "Уганды",
    ua: "Украины",
    ae: "Объединенных Арабских Эмират",
    gb: "Соединенного Королевства Великобритании и Северной Ирландии",
    tz: "Объединенной Республики Танзания",
    um: "Внешних малых островов Coeдинeнныx Штaтoв",
    vi: "Виргинских oстровов Coeдинeнныx Штaтoв",
    us: "США",
    uy: "Уругвая",
    uz: "Узбекистана",
    vu: "Вануату",
    ve: "Венесуэлы",
    vn: "Вьетнама",
    wf: "Островов Уоллис и Футуна",
    eh: "Западной Сахары",
    ye: "Йемена",
    zm: "Замбии",
    zw: "Зимбабве",
    ax: "Аландских островов",
    undef: "международного сайта"
  },
};

export default Object.assign({}, enUS, ruRU);
