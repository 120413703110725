import enUS from '../en-us';

export const frCA = {
  common: {
    ctas: {
      close: 'Fermer',
      learnMore: 'Apprenez-en davantage',
      learnMoreAbout: 'Apprenez-en davantage sur',
      viewDetails: 'Voir les détails',
      viewDetailsAbout: 'Voir les détails sur',
      viewRecipe: 'Voir la recette',
      contactUs: 'Communiquez avec nous',
    },
    navigation: {
      regionSelectorLabel: 'Sélectionneur de région',
      primaryNavLabel: 'Sélectionneur de région',
      whereToBuy: 'Où acheter',
      selectYourRegion: 'Sélectionnez votre région',
      skipNavigation: 'Sauter au contenu principal',
    },
    faq: {
      faqs: 'Foire aux questions',
    },
    forms: {
      general: {
        /* eslint-disable max-len */
        termsLegend: 'vérifie si tu es d\'accord',
        fieldErrorMsg: 'Ce champ est vide ou invalide, veuillez le remplir adéquatement avant l\'envoi',
        fileInvalidType: 'Un fichier envoyé est invalide, veuillez réessayer',
        fileTooLarge: 'Un fichier envoyé est trop grand, veuillez télécharger une version plus petite',
        fileTooSmall: 'Un fichier envoyé est vide, veuillez réessayer',
        tooManyFiles: 'Un fichier envoyé est vide, veuillez réessayer',
        submitBtnText: 'Envoyer',
        submitErrorMsg: 'Veuillez vous assurer que tous les champs requis sont bien remplis et réessayez.',
        networkErrorMsg: 'Une erreur est survenue sur le serveur pendant le traitement de la demande. Veuillez réessayer ou contactez-nous par téléphone ou par courriel',
        successMsg: 'Vos informations ont été envoyées avec succès',
        selectFile: 'Sélectionnez un fichier',
        dragAndDrop: 'Glissez et déposez vos fichiers ici ou cliquez pour sélectionner',
        files: 'Fichiers',
        fileLimit: 'Veuillez joindre un maximum de ${1} fichiers',
        textTooLong: 'Ce champ est limité à ${1} caractères',
        textTooShort: 'Ce champ requiert un minimum de ${1} caractères',
        /* eslint-enable max-len */
      },
    },
    rate: {
      starLabel: 'étoile',
      starsLabel: 'étoiles',
    },
    success: {
      message: 'Merci pour votre avis!',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: 'Détaillants locaux',
        buyOnlineText: 'Achetez en ligne',
      },
      inputLocation: {
        label: 'Entrez le lieu ou le code postal',
        submit: 'Soumettre la recherche',
      },
      mapControls: {
        curbsideLabel: 'Ramassage à l\'auto disponible',
        shopOnlineLabel: 'Magasinage en ligne disponible',
        zoomInLabel: 'Agrandir la carte',
        zoomOutLabel: 'Rapetisser la carte',
      },
      filters: {
        filters: 'Filtres',
        filterApplied: 'Filtre appliqué',
        filtersApplied: 'Filtres appliqués',
        clear: 'Effacer',
        clearFilters: 'Effacer les filtres',
        openFilters: 'Ouvrir les filtres',
        closeFilters: 'Fermer les filtres',
        appliedFilters: 'Il y a ${1} filtres appliqués, appuyez sur Entrée pour fermer les filtres',
        noFiltersApplied: '0 filtre appliqué',
        noFilters: 'Aucun filtre sélectionné',
        numFiltersApplied: 'Appliquer ${1} Filtres',
        numFilterApplied: 'Appliquer 1 filtre',
        filterStores: 'Filtrer les magasins',
      },
      map: {
        title: 'Emplacements où les recettes ${1} sont disponibles',
      },
      infoList: {
        closeIcon: 'Fermer la carte',
        curbsidePickup: 'Ramassage à l\'auto disponible',
        directions: 'Directions',
        website: 'Site Web',
        buyOnline: 'Achetez en ligne',
        facebook: 'Facebook',
        mi: 'Distance de chez vous en miles',
        km: 'Distance de chez vous en kilomètres',
        loadMore: 'Charger plus',
        loadMoreStores: 'Charger plus de magasins',
      },
    },
    redirectModal: {
      headline: 'Nous avons détecté votre emplacement en tant que : ${1}?',
      subheadline: 'Visitez votre site régional pour plus d\'information pertinente',
      redirectCta: 'Aller au site régional',
      stayCta: 'Rester ici',
      closeModal: 'Rester ici',
    },
    confirmationModal: {
      headline: 'Souhaitez-vous vraiment quitter?',
      yes: 'Oui',
      no: 'Non',
    },
  },
  product: {
    hero: {
      review: '1 commentaire',
      reviews: '${1} commentaires',
      askAQuestion: 'Posez une question',
      whereToBuy: 'Où acheter',
    },
    tabs: {
      whatsInside: 'Qu\'est-ce qu\'il y a à l\'intérieur?',
      nutritionalInformation: 'Information nutritionnelle',
      header: {
        gather: "Ingrédients naturels et certifiés de nos partenaires de confiance",
        goSolutions: "Ingrédients de qualité provenant de partenaires de confiance",
        nowFresh: "Une alimentation parfaitement équilibrée pour votre animal de compagnie",
        summit: "Nutrition accessible dont votre animal de compagnie raffolera",
      }
    },
    table: {
      composition: 'Composition',
      additives: 'Additifs par KG',
      calorieContent: 'Teneur en calories',
      recommendedFor: 'Recommandée pour ',
      ingredients: 'Ingrédients',
      analyticalConstituents: 'Constituants analytiques ',
      amount: 'Quantité',
      guaranteedAnalysis: 'Analyse garantie',
      viewMore: 'Voir tout',
      viewLess: 'Voir moins',
      downloadNutrientProfile: 'Télécharger le profil nutritionnel',
    },
    review: {
      sectionTitle: 'Que disent d\'autres parents d\'animaux',
      writeAReview: 'Écrivez un commentaire',
      productStandouts: 'Points forts',
      noReviewTitle: 'Nous voulons savoir ce que votre animal de compagnie en pense!',
      noReviewSubtitle: 'Il n\'y a pas de commentaires pour ce produit.',
      readMore: 'Lisez plus',
      readMoreFrom: 'Lisez plus de ${1}',
      lessInformation: 'Moins d\'information',
      portraitRespondent: 'Portrait du répondant',
      previousPage: 'Page précédente',
      nextPage: 'Page suivante',
      pageNumber: 'Page ${1}',
      sortReviews: 'Trier les avis',
      sortLabel: 'Trier par:',
      loadingMore: 'Chargement…',
      noMore: 'Aucun avis',
      loadMoreCta: 'Charger plus',
      aggregateSummary: 'Basé sur ${1} avis',
      pagination: 'Affiche ${1} avis sur ${2}',
      feedingSince: 'Nourriture pour',
      petInfoTitle: 'Informations sur l\'animal de compagnie',
      petName: 'Nom :',
      petAge: 'Âge :',
      petBreed: 'Race :',
      helpfulPrompt: 'Était-ce utile?',
      helpfulPositive: 'Oui',
      helpfulNegative: 'Non',
      authorRecommends: 'Je recommande ce produit',
      viewLargeImage: 'Agrandir l\'image',
      responseIntro: 'Réponse de ${1}',
      showOriginal: 'Afficher l\'original',
      translatedBy: 'Traduit par Google',
      translateWith: 'Traduit avec Google',
      standouts: {
        pickyEaterApproved: 'Approuvée par les mangeurs difficiles',
        improvedHealth: 'Santé améliorée',
        easyDigestion: 'Facile à digérer',
        skinCoatResults: 'Résultats sur la peau/le pelage',
        happyPetParent: 'Parent d\'animal heureux',
        increasedEnergy: 'Énergie accrue',
      },
      filters: {
        filterReviews: 'Filtrer les avis',
        filterApplied: '${1} Filtre appliqué',
        filtersApplied: '${1} Filtres appliqués',
        result: '${1} Résultat',
        results: '${1} Résultats',
        filters: 'Filtres',
        filterRecipes: 'Filtrer les recettes',
        noFilters: 'Aucun filtre sélectionné',
        numFiltersApplied: 'Appliquer ${1} filtres',
        numFilterApplied: 'Appliquer 1 filtre',
        clear: 'Effacer',
        openFilters: 'Ouvrir les filtres',
        closeFilters: 'Fermer les filtres',
        clearFilters: 'Effacer les filtres',
        // eslint-disable-next-line max-len
        appliedFilters: 'Il y a ${1} filtres appliqués, appuyez sur Entrée pour fermer les filtres',
        noFiltersApplied: '0 filtre appliqué',
        numStars: '${1} Étoiles',
        numStar: '${1} Étoile',
        numReviews: '${1} commentaires',
        numReview: '1 commentaire',
        rating: 'Classement',
      },
      sort: {
        oldest: 'Le plus ancien',
        newest: 'Le plus récent',
        topRated: 'Le mieux classé',
        lowRated: 'Le moins bien classé',
        helpfulness: 'Le plus utile',
      },
    },
    feedingGuide: {
      eyebrow: 'Guide d\'alimentation',
      dogTitle: 'Trouvez la bonne quantité pour votre chien',
      catTitle: 'Trouvez la bonne quantité pour votre chat',
      moreInfo: 'Information détaillée',
      bodyChartCTALabel: 'Tableau de l\'état corporel',
      petNeeds: 'Votre animal a besoin d\'environ ${1} par jour.',
      contactText: 'Contactez-nous et nous vous aiderons à trouver la recette parfaite.',
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: '${1} Filtre appliqué',
      filtersApplied: '${1} Filtres appliqués',
      result: '${1} Résultat',
      results: '${1} Résultats',
      filters: 'Filtres',
      filterRecipes: 'Filtrer les recettes',
      noFilters: 'Aucun filtre sélectionné',
      numFiltersApplied: 'Appliquer ${1} filtres',
      numFilterApplied: 'Appliquer 1 filtre',
      clear: 'Effacer',
      openFilters: 'Ouvrir les filtres',
      closeFilters: 'Fermer les filtres',
      clearFilters: 'Effacer les filtres',
      appliedFilters: 'Il y a ${1} filtres appliqués, appuyez sur Entrée pour fermer les filtres',
      noFiltersApplied: '0 filtre appliqué',
      filterCategories: {
        petType: 'Type d\’animal',
      }
    },
  },
  categoryLandingSyntheticTags: {
    dog: 'Chien',
    cat: 'Chat'
  },
  blogPost: {
    authors: {
      authors: 'Auteurs',
      author: 'Auteur',
    },
  },
  blogLanding: {
    featured: 'Produits vedettes',
    pagination: '${1} - ${2} de ${3} d\'articles affichés',
  },
  countries: {
    af: 'Afghanistan',
    za: 'Afrique du Sud',
    ax: 'Îles Åland',
    al: 'Albanie',
    dz: 'Algérie',
    de: 'Allemagne',
    ad: 'Andorre',
    ao: 'Angola',
    ai: 'Anguilla',
    aq: 'Antarctique',
    ag: 'Antigua-et-Barbuda',
    sa: 'Arabie saoudite',
    ar: 'Argentine',
    am: 'Arménie',
    aw: 'Aruba',
    au: 'Australie',
    at: 'Autriche',
    az: 'Azerbaïdjan',
    bs: 'Bahamas',
    bh: 'Bahreïn',
    bd: 'Bangladesh',
    bb: 'Barbade',
    by: 'Biélorussie',
    be: 'Belgique',
    bz: 'Belize',
    bj: 'Bénin',
    bm: 'Bermudes',
    bt: 'Bhoutan',
    bo: 'Bolivie',
    bq: 'Pays-Bas caribéens',
    ba: 'Bosnie-Herzégovine',
    bw: 'Botswana',
    bv: 'Île Bouvet',
    br: 'Brésil',
    bn: 'Brunei',
    bg: 'Bulgarie',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    ky: 'Îles Caïmans',
    kh: 'Cambodge',
    cm: 'Cameroun',
    ca: 'Canada (Français)',
    cv: 'Cap-Vert',
    cf: 'République centrafricaine',
    cl: 'Chili',
    cn: 'Chine',
    cx: 'Île Christmas',
    cy: 'Chypre (pays)',
    cc: 'Îles Cocos',
    co: 'Colombie',
    km: 'Comores (pays)',
    cg: 'République du Congo',
    cd: 'République démocratique du Congo',
    ck: 'Îles Cook',
    kr: 'Corée du Sud',
    kp: 'Corée du Nord',
    cr: 'Costa Rica',
    ci: 'Côte d\'Ivoire',
    hr: 'Croatie',
    cu: 'Cuba',
    cw: 'Curaçao',
    dk: 'Danemark',
    dj: 'Djibouti',
    do: 'République dominicaine',
    dm: 'Dominique',
    eg: 'Égypte',
    sv: 'Salvador',
    ae: 'Émirats arabes unis',
    ec: 'Équateur (pays)',
    er: 'Érythrée',
    es: 'Espagne',
    ee: 'Estonie',
    us: 'États-Unis',
    et: 'Éthiopie',
    fk: 'Malouines',
    fo: 'Îles Féroé',
    fj: 'Fidji',
    fi: 'Finlande',
    fr: 'France',
    ga: 'Gabon',
    gm: 'Gambie',
    ge: 'Géorgie (pays)',
    gs: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Grèce',
    gd: 'Grenade (pays)',
    gl: 'Groenland',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernesey',
    gn: 'Guinée',
    gw: 'Guinée-Bissau',
    gq: 'Guinée équatoriale',
    gy: 'Guyana',
    gf: 'Guyane',
    ht: 'Haïti',
    hm: 'Îles Heard-et-MacDonald',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hongrie',
    im: 'Île de Man',
    um: 'Îles mineures éloignées des États-Unis',
    vg: 'Îles Vierges britanniques',
    vi: 'Îles Vierges des États-Unis',
    in: 'Inde',
    id: 'Indonésie',
    ir: 'Iran',
    iq: 'Irak',
    ie: 'Irlande (pays)',
    is: 'Islande',
    il: 'Israël',
    it: 'Italie',
    jm: 'Jamaïque',
    jp: 'Japon',
    je: 'Jersey',
    jo: 'Jordanie',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    kg: 'Kirghizistan',
    ki: 'Kiribati',
    kw: 'Koweït',
    la: 'Laos',
    ls: 'Lesotho',
    lv: 'Lettonie',
    lb: 'Liban',
    lr: 'Liberia',
    ly: 'Libye',
    li: 'Liechtenstein',
    lt: 'Lituanie',
    lu: 'Luxembourg (pays)',
    mo: 'Macao',
    mk: 'Macédoine du Nord',
    mg: 'Madagascar',
    my: 'Malaisie',
    mw: 'Malawi',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malte',
    mp: 'Îles Mariannes du Nord',
    ma: 'Maroc',
    mh: 'Îles Marshall (pays)',
    mq: 'Martinique',
    mu: 'Maurice (pays)',
    mr: 'Mauritanie',
    yt: 'Mayotte',
    mx: 'Mexique',
    fm: 'États fédérés de Micronésie (pays)',
    md: 'Moldavie',
    mc: 'Monaco',
    mn: 'Mongolie',
    me: 'Monténégro',
    ms: 'Montserrat',
    mz: 'Mozambique',
    mm: 'Birmanie',
    na: 'Namibie',
    nr: 'Nauru',
    np: 'Népal',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Île Norfolk',
    no: 'Norvège',
    nc: 'Nouvelle-Calédonie',
    nz: 'Nouvelle-Zélande',
    io: 'Territoire britannique de l\'océan Indien',
    om: 'Oman',
    ug: 'Ouganda',
    uz: 'Ouzbékistan',
    pk: 'Pakistan',
    pw: 'Palaos',
    ps: 'Palestine',
    pa: 'Panama',
    pg: 'Papouasie-Nouvelle-Guinée',
    py: 'Paraguay',
    nl: 'Pays-Bas',
    pe: 'Pérou',
    ph: 'Philippines',
    pn: 'Îles Pitcairn',
    pl: 'Pologne',
    pf: 'Polynésie française',
    pr: 'Porto Rico',
    pt: 'Portugal',
    qa: 'Qatar',
    re: 'La Réunion',
    ro: 'Roumanie',
    gb: 'Royaume-Uni',
    ru: 'Russie',
    rw: 'Rwanda',
    eh: 'République arabe sahraouie démocratique',
    bl: 'Saint-Barthélemy',
    kn: 'Saint-Christophe-et-Niévès',
    sm: 'Saint-Marin',
    mf: 'Saint-Martin',
    sx: 'Saint-Martin',
    pm: 'Saint-Pierre-et-Miquelon',
    va: 'Saint-Siège (État de la Cité du Vatican)',
    vc: 'Saint-Vincent-et-les-Grenadines',
    sh: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    lc: 'Sainte-Lucie',
    sb: 'Îles Salomon',
    ws: 'Samoa',
    as: 'Samoa américaines',
    st: 'Sao Tomé-et-Principe',
    sn: 'Sénégal',
    rs: 'Serbie',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapour',
    sk: 'Slovaquie',
    si: 'Slovénie',
    so: 'Somalie',
    sd: 'Soudan',
    ss: 'Soudan du Sud',
    lk: 'Sri Lanka',
    se: 'Suède',
    ch: 'Suisse',
    sr: 'Suriname',
    sj: 'Svalbard et ile Jan Mayen',
    sz: 'Eswatini',
    sy: 'Syrie',
    tj: 'Tadjikistan',
    tw: 'Taïwan',
    tz: 'Tanzanie',
    td: 'Tchad',
    cz: 'Tchéquie',
    tf: 'Terres australes et antarctiques françaises',
    th: 'Thaïlande',
    tl: 'Timor oriental',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinité-et-Tobago',
    tn: 'Tunisie',
    tm: 'Turkménistan',
    tc: 'Îles Turques-et-Caïques',
    tr: 'Turquie',
    tv: 'Tuvalu',
    ua: 'Ukraine',
    uy: 'Uruguay',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Viêt Nam',
    wf: 'Wallis-et-Futuna',
    ye: 'Yémen',
    zm: 'Zambie',
    zw: 'Zimbabwe',
    undef: 'International'
  },
};

export default Object.assign({}, enUS, frCA);
