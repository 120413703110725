export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

/**
 * Sends a new url to notify GA a pageview change
 * @param {string} [url] new url to set the pageview in GA
 */
export const pageview = (url) => {
  if (window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};
