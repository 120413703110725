import enUS from './en-us';
import enCA from './en-ca';
import frCA from './fr-ca';
import enEU from './en-eu';
import en from './en';
import de from './de';
import ruRU from './ru-ru';
import zhCN from './zh-cn';
import heIL from './he-il';

export default {
  de,
  en,
  'en-us': enUS,
  'en-ca': enCA,
  'fr-ca': frCA,
  'en-eu': enEU,
  'ru-ru': ruRU,
  'zh-cn': zhCN,
  'he-il': heIL,
};
