import { createContext } from 'react';
import defaultStrings from '../translations/locales/en-us';
import locales from '../translations/locales';
import { defaultLocale } from '../siteConfig';

export const LanguageContext = createContext({
  localization: {
    locale: defaultLocale,
    translations: defaultStrings.common,
    namespace: 'common',
  },
});

/**
 * @param {object} [props] component props
 * @param {object} [props.localization] object to be used as localization
 * @param {JSX.Element} [props.children] element to be wrapped with localization context
 * @returns {JSX.Element} react component with localization context
 */
export function LanguageProvider({ localization, children }) {
  return <LanguageContext.Provider value={{ localization }}>{children}</LanguageContext.Provider>;
}

/**
 * @param {string} [langCode] code to be looked in localization files
 * @param {string} [namespace] namespace from translation file to be retrieved
 * @returns {object} localization object from given parameters
 */
export function getLocalizationProps(langCode = defaultLocale, namespace = 'common') {
  const locale = locales[langCode] ? locales[langCode] : locales[defaultLocale];
  const strings = locale[namespace];
  const translations = {
    common: locale.common,
    ...strings,
  };
  return {
    locale: langCode,
    translations,
    namespace,
  };
}
