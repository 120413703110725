/* supported locales from the app */
export const locales = ['en-eu', 'en-us', 'en-ca', 'de', 'en', 'ru-ru', 'zh-cn', 'fr-ca', 'he-il'];

// eslint-disable-next-line max-len
export const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE
  ? process.env.NEXT_PUBLIC_DEFAULT_LOCALE
  : 'en-us';

// used for region selector display
export const languageNames = {
  'en-ca': 'English (Canada)',
  'fr-ca': 'Français (Canada)',
  'en-us': 'English (United States)',
  'en-eu': 'English (Europe)',
  en: 'English (International)',
  de: 'Deutsche',
  'ru-ru': 'Россия',
  'zh-cn': '简体中文 (中国)',
  'he-il': 'עברית (ישראל)',
};

/* This map is to set the available locales for country in the file
config.js from the translations folder, you'll find the available locales
in the app
 */
export const countryLocaleMap = {
  at: ['en-eu'],
  be: ['en-eu'],
  bg: ['en-eu'],
  ca: ['en-ca', 'fr-ca'],
  cy: ['en-eu'],
  cz: ['en-eu'],
  de: ['de-de', 'en-eu'],
  dk: ['en-eu'],
  ee: ['en-eu'],
  es: ['en-eu'],
  fi: ['en-eu'],
  fr: ['en-eu'],
  gr: ['en-eu'],
  hr: ['en-eu'],
  hu: ['en-eu'],
  ie: ['en-eu'],
  it: ['en-eu'],
  lt: ['en-eu'],
  lu: ['en-eu'],
  lv: ['en-eu'],
  mt: ['en-eu'],
  nl: ['en-eu'],
  pl: ['en-eu'],
  pt: ['en-eu'],
  ro: ['en-eu'],
  se: ['en-eu'],
  sk: ['en-eu'],
  si: ['en-eu'],
  us: ['en-us'],
  ru: ['ru-ru'],
  cn: ['zh-cn'],
  il: ['he-il'],
};

/**
 * This const defines the content types in the application.
 *
 */
export const contentTypes = [
  'product',
  'blogPost',
  'categoryLanding',
  'storeLocator',
  'blogLanding',
  'philosophy',
  'freeFormTemplate',
];

/**
 * This const defines locales that we restrict from submitting reviews
 * This is often done for privacy law reasons
 *
 */
export const reviewViewOnlyLocales = ['zh-cn', 'ru-ru'];

// mapping the external font urls to the brand name and locale
// we will use the default locale if the locale is not defined
// if the locale is null, we will not load the default font
export const externalFontMap = {
  gather: {
    default: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap',
  },
  'go-solutions': {
    'ru-ru': 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap',
    'he-il': 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap',
  },
  'now-fresh': {
    default: 'https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap',
    'ru-ru': [
      'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700;800&display=swap',
      'https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap',
    ],
    'he-il':
      // eslint-disable-next-line max-len
      'https://fonts.googleapis.com/css2?family=Noto+Serif+Hebrew:wght@400;500;700;800&display=swap',
  },
  petcurean: {
    default: 'https://use.typekit.net/vwu3fnu.css',
    'zh-cn': null,
  },
  summit: {
    default: 'https://use.typekit.net/bxd7xlz.css',
  },
};
